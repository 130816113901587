.nothing__main-content{
    align-items: center;
    background-color: $primary;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100vh;

    p {
        text-align: center;
    }
}