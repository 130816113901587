.auth__main {
    align-items: center;
    background-color: $primary;
    display: flex;
    justify-content: center;
    margin: 0px;
    height: 100vh;
    width: 100vw;
}

.auth__box-container {
    background-color: white;
    box-shadow: 0px 3px $dark-grey;
    border-radius: 2px;
    padding: 20px;
    width: 250px;
}

.auth__title{
    color: darken($color: $primary, $amount: 20);
    margin-bottom: 20px;

}

.auth__input{
    color: $dark-grey;
    border: 0px;
    border-bottom: 1px solid $light-grey;
    font-size: 16px;
    margin-bottom: 10px;
    height: 20px;
    width: 100%;

    transition: border-botton .3s ease;

    &:focus{
        border-bottom: 1px solid $primary;
        outline: none;
    }

}

.auth__social-networks {
    align-items: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding-top: 20px;
    padding-bottom: 20px;
    width: 100%;
}

.auth__alert-error{
    background-color: red;
    border-radius: 5px;
    color: white;
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
    padding: 5px;
    



}